import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  CircularProgress,
  Typography,
  DialogContentText,
  SelectChangeEvent,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Tenant, Endpoint } from "../../types";
import ParchaApi from "../../libs/ParchaApi";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

interface EditTenantAgentsModalProps {
  open: boolean;
  onClose: () => void;
  onUpdateTenant: (updatedTenant: Tenant) => void;
  tenant: Tenant;
  parchaApi: ParchaApi;
}

const EditTenantAgentsModal: React.FC<EditTenantAgentsModalProps> = ({
  open,
  onClose,
  onUpdateTenant,
  tenant,
  parchaApi,
}) => {
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [availableAgents, setAvailableAgents] = useState<Endpoint[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (open && tenant) {
        setIsLoading(true);
        try {
          setSelectedAgents(tenant.customAttributes.agents || []);
          const { endpoints } = await parchaApi.getCustomerAgents();
          setAvailableAgents(endpoints);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [open, tenant, parchaApi]);

  const handleAgentChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedAgents(event.target.value as string[]);
  };

  const isChanged = useMemo(() => {
    if (!tenant) return false;
    const currentAgents = new Set(tenant.customAttributes.agents || []);
    const newAgents = new Set(selectedAgents);
    return currentAgents.size !== newAgents.size || [...currentAgents].some((agent) => !newAgents.has(agent));
  }, [tenant, selectedAgents]);

  const handleSubmit = async () => {
    if (isChanged) {
      setConfirmDialogOpen(true);
    }
  };

  const handleConfirmUpdate = async () => {
    setConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      if (!tenant) throw new Error("Tenant data is not available");
      const updatedTenant = {
        ...tenant,
        customAttributes: { ...tenant.customAttributes, agents: selectedAgents },
      };
      await parchaApi.updateTenantAgents(updatedTenant);
      onUpdateTenant(updatedTenant);
      onClose();
    } catch (error) {
      console.error("Error updating tenant agents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelUpdate = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <EditIcon sx={{ marginRight: "8px" }} />
          Edit Agents: {tenant?.name || "Loading..."}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              height: 20,
              width: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "400px", pt: 2 }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                Select the agent keys for this tenant:
              </Typography>
              <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
                <InputLabel id="agent-keys-label">Agent Keys</InputLabel>
                <Select
                  labelId="agent-keys-label"
                  multiple
                  input={<OutlinedInput label="Agent Keys" />}
                  value={selectedAgents}
                  onChange={handleAgentChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          color="primary"
                          variant="outlined"
                          label={availableAgents?.find((agent) => agent.agentKey === value)?.agentName || value}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {availableAgents?.map((agent) => (
                    <MenuItem key={agent.agentKey} value={agent.agentKey}>
                      {agent.agentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: "divider", padding: 2 }}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading || !isChanged} variant="contained">
            Update Agents
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Agent Keys Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update the agent keys for {tenant?.name || "Loading..."}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate}>Cancel</Button>
          <Button onClick={handleConfirmUpdate} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTenantAgentsModal;
