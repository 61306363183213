// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import EmbedChecksLayout from "./EmbedChecksLayout";

const EmbedChecksViewByCaseId = () => {
  const parchaApi = useParchaApi();
  const [searchParams] = useSearchParams();
  const endpointUrl = searchParams.get("endpoint_url") || import.meta.env.VITE_API_ENDPOINT_URL;
  const agentKey = searchParams.get("agent_key") || import.meta.env.VITE_AGENT_KEY;
  const caseId = searchParams.get("case_id");
  const apiKey = searchParams.get("api_key");
  const [error, setError] = useState<string | null>(null);
  const [checks, setChecks] = useState<any[] | null>(null);
  const [jobMetadata, setJobMetadata] = useState<any | null>(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchJobData = () => {
      if (!caseId || !endpointUrl || !apiKey) {
        return;
      }

      parchaApi.getJobMetadataByCaseId(endpointUrl, caseId, agentKey, apiKey).then((data) => {
        setJobMetadata(data);
        const checks = data.kyc
          ? Object.values(data.kyc)[0]?.check_results?.filter((check: any) => check.command_id !== "finish_plan")
          : [];
        setChecks(checks);
      });
    };

    if (caseId && endpointUrl && apiKey) {
      fetchJobData();
      intervalRef.current = setInterval(fetchJobData, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [caseId, endpointUrl, apiKey]);

  useEffect(() => {
    if (jobMetadata?.job?.status === "complete" || jobMetadata?.job?.status === "error") {
      clearInterval(intervalRef.current);
    }
  }, [jobMetadata?.job?.status]);

  if (caseId && agentKey) {
    document.title = `Parcha - ${caseId} - ${agentKey}`;
  }

  return (
    <EmbedChecksLayout
      jobMetadata={jobMetadata}
      checks={checks || []}
      error={error}
      agentKey={agentKey}
      apiKey={apiKey}
      endpointUrl={endpointUrl}
      onRefresh={() => window.location.reload()}
    />
  );
};

export default EmbedChecksViewByCaseId;
