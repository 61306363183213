// @ts-nocheck
import { useRef, useState } from "react";
import _ from "lodash";
import {
  ErrorRounded,
  RefreshRounded,
  EmailRounded,
  HourglassTopRounded,
  LocationOnRounded,
  CalendarTodayRounded,
} from "@mui/icons-material";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import Alert from "./TailwindComponents/Alert";
import StatusIndicator from "./TailwindComponents/StatusIndicator";
import { differenceInYears, format, parseISO } from "date-fns";
import RecommendationBox from "./RecommendationBox";

interface EmbedChecksLayoutProps {
  jobMetadata: any;
  checks: any[];
  error: string | null;
  agentKey: string;
  apiKey: string;
  endpointUrl: string;
  onRefresh: () => void;
}

const EmbedChecksLayout = ({
  jobMetadata,
  checks,
  error,
  agentKey,
  apiKey,
  endpointUrl,
  onRefresh,
  displayOnlyMatches = false,
}: EmbedChecksLayoutProps) => {
  const [toastState, setToastState] = useState<{ show: boolean; status: string }>({
    show: false,
    status: "in progress",
  });
  const reportContainerRef = useRef(null);

  const selfAttestedData = jobMetadata?.job?.input_payload?.self_attested_data;

  const onContactSupportClickHandler = () => {
    let mailtoLink;
    if (error) {
      const subject = `Error report in ${agentKey}`;
      const body = `The following error occurred while loading the data.\n\n${error}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    } else {
      const subject = `Error report in ${agentKey} for job ${jobMetadata?.job?.id}`;
      const body = `There was an error with job ID ${jobMetadata?.job?.id}.\n\n`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    }
    window.open(mailtoLink, "_blank");
  };

  const Toast = () => {
    if (!toastState.show) return null;
    return <div className="fixed top-4 right-4 z-50">{/* ... Toast implementation ... */}</div>;
  };

  return (
    <div className="relative flex flex-col gap-y-4 h-full overflow-auto px-8">
      <Toast />
      <div id="report-container" ref={reportContainerRef} className="flex flex-col gap-y-2 h-full">
        {selfAttestedData && (
          <div className="flex justify-between items-baseline p-5 bg-slate-50 text-xs text-slate-900 border-b border-slate-200">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm font-semibold">
                {selfAttestedData?.first_name} {selfAttestedData?.last_name}
              </span>
              <div className="flex items-center gap-x-5 text-slate-700">
                {selfAttestedData?.date_of_birth && (
                  <div className="flex items-center gap-x-1">
                    <CalendarTodayRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                    <span>
                      {differenceInYears(new Date(), parseISO(selfAttestedData.date_of_birth))},{" "}
                      {format(parseISO(selfAttestedData.date_of_birth), "MMMM do yyyy")}
                    </span>
                  </div>
                )}
                {selfAttestedData?.country_of_residence && (
                  <div className="flex items-center gap-x-1">
                    <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                    <span>{selfAttestedData?.country_of_residence}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-2 items-end">
              {jobMetadata?.job?.status === "complete" ? (
                <RecommendationBox recommendation={jobMetadata?.job?.recommendation} />
              ) : null}
              {jobMetadata?.job?.status === "queued" && (
                <div className="flex items-center gap-x-1">
                  <StatusIndicator status={jobMetadata?.job?.status} />
                  <span className="text-xs text-slate-500 capitalize">{jobMetadata?.job?.status}</span>
                </div>
              )}
            </div>
          </div>
        )}

        {jobMetadata?.job?.status === "queued" ? (
          <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
            <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
            <div className="text-center">
              <p className="font-bold">This job is queued up</p>
              <p>It will start executing once the current in progress jobs are completed.</p>
            </div>
          </div>
        ) : jobMetadata?.job?.status === "error" || error ? (
          <div className="flex items-center h-full mx-auto w-3/4">
            <div className="flex flex-col justify-center items-center w-full py-10 px-5 lg:p-10 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
              <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
              <div className="text-center">
                <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">An error has occurred</p>
                {error && <p className="text-slate-500 text-xs">There was an issue loading the data.</p>}
                <button
                  type="button"
                  className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10"
                  onClick={onRefresh}
                >
                  <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1 text-xs">Refresh This Page</span>
                </button>
                <p className="mt-4 text-xs text-slate-500">
                  If the problem persists please contact support and we will look into the issue as soon as possible.
                </p>
                <button
                  type="button"
                  className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10"
                  onClick={onContactSupportClickHandler}
                >
                  <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1 text-xs">Contact Support</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {jobMetadata?.job?.status !== "queued" && checks && checks.length > 0 && (
          <div className="flex flex-col gap-y-4">
            {[...checks]
              .sort((a, b) => a.command_name.localeCompare(b.command_name))
              .map((check) => (
                <div
                  key={check.command_instance_id}
                  className="flex flex-col gap-y-2 relative mb-8 pb-8 border-b border-slate-200"
                >
                  <h2 className="text-xl font-medium text-slate-900 px-5">
                    {check.command_name.replace(" Check", "")}
                  </h2>
                  {check.recommendation && (
                    <Alert
                      level={
                        check.recommendation === "Approve"
                          ? "success"
                          : check.recommendation === "Review"
                            ? "partial"
                            : check.recommendation === "Deny"
                              ? "failure"
                              : "normal"
                      }
                      message={
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center gap-x-2">
                            {check.recommendation && check.answer && (
                              <span>
                                <span className="capitalize font-semibold">
                                  {check.recommendation === "Approve"
                                    ? "Low Risk"
                                    : check.recommendation === "Review"
                                      ? "Medium Risk"
                                      : check.recommendation === "Deny"
                                        ? "High Risk"
                                        : check.recommendation}
                                  :
                                </span>{" "}
                                {check.answer}
                              </span>
                            )}
                          </div>
                        </div>
                      }
                    />
                  )}
                  <CheckContainer
                    title={check.command_name}
                    agentRun={jobMetadata?.job}
                    apiKey={apiKey}
                    endpointUrl={endpointUrl}
                    commandResult={check}
                    messages={check.status_messages || []}
                    auditLogMessages={[]}
                    compact={true}
                    showAllContent={true}
                    auditLogVisible={false}
                    displayOnlyMatches={true}
                    hideFeedbackControls={true}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmbedChecksLayout;
