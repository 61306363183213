import { useContext, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import ApiIcon from '@mui/icons-material/Api';
import BusinessIcon from '@mui/icons-material/Business';
import { styled } from '@mui/material/styles';
import UserContext from "@/contexts/UserContext";

const LeftAlignedTab = styled(Tab)(({ theme }) => ({
    minHeight: 48,
    padding: theme.spacing(1, 2),
    justifyContent: 'flex-start',
    '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
        marginBottom: 0,
    },
}));

export default function AdminView() {
    const location = useLocation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { hasRole } = userContext;

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(`/admin/${newValue}`);
    };

    // Determine which tab to show initially based on permissions
    useEffect(() => {
        if (location.pathname === '/admin') {
            if (hasRole('admin')) {
                navigate('/admin/users');
            } else if (hasRole('developer')) {
                navigate('/admin/apiKeys');
            } else if (hasRole('parchaAdmin')) {
                navigate('/admin/tenants');
            }
        }
    }, [hasRole, location.pathname, navigate]);

    const selectedTab = location.pathname.split('/')[2] || '';

    return (
        <Box className="flex h-full max-w-[1400px]">
            <Box className="w-64 border-r border-slate-200 flex-shrink-0">
                <Box className="border-b border-slate-200">
                    <Box className="flex items-center px-4 py-3 h-[3.75rem]">
                        <Box className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-2">
                            <SettingsIcon className="text-blue-500" fontSize="small" />
                        </Box>
                        <Typography variant="h6" className="text-gray-700">
                            Settings
                        </Typography>
                    </Box>
                </Box>
                <Tabs
                    orientation="vertical"
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Admin Tabs"
                    className="h-full w-full"
                    sx={{
                        '& .MuiTabs-indicator': {
                            right: 0,
                            left: 'auto',
                        },
                    }}
                >
                    {hasRole('admin') && (
                        <LeftAlignedTab
                            value="users"
                            icon={<GroupIcon />}
                            label={<span style={{ textTransform: 'uppercase', fontSize: '0.875rem' }}>Users</span>}
                            iconPosition="start"
                        />
                    )}
                    {hasRole('developer') && (
                        <LeftAlignedTab
                            value="apiKeys"
                            icon={<ApiIcon />}
                            label={<span style={{ textTransform: 'uppercase', fontSize: '0.875rem' }}>API Keys</span>}
                            iconPosition="start"
                        />
                    )}
                    {hasRole('parchaAdmin') && (
                        <LeftAlignedTab
                            value="tenants"
                            icon={<BusinessIcon />}
                            label={<span style={{ textTransform: 'uppercase', fontSize: '0.875rem' }}>Tenants</span>}
                            iconPosition="start"
                        />
                    )}
                </Tabs>
            </Box>
            <Box className="flex-grow overflow-auto">
                <Outlet />
            </Box>
        </Box>
    );
}
