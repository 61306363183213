import { useEffect, useState, useMemo } from "react";
import { Column, useTable } from "react-table";
import Select, { SingleValue } from "react-select";
import { format } from "date-fns";
import Icon from "@mui/material/Icon";
import { ChevronRight, FactCheck } from "@mui/icons-material";
import { useNavigate, Link, useSearchParams, Navigate } from "react-router-dom";
import { Agent, Endpoint, EndpointsAndTenant, Step } from "@/types";
import { twJoin } from "tailwind-merge";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import { getJobName } from "@/utils";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";

type EndpointSelection = {
  label: string;
  value: string;
};

type CheckDataItem = {
  agent_key: string;
  command_id: string;
  command_name: string;
  accuracy_percentage: number;
  total: number;
  total_approve: number;
  total_deny: number;
  total_review: number;
  total_thumbs_down: number;
  total_comments: number;
  total_passed_true: number;
  total_passed_false: number;
  // Add other properties as needed
};

type CheckFeedbackDataItem = {
  job_id: string;
  command_id: string;
  command_name: string;
  input_payload: any;
  recommendation: string | null;
  score: boolean | null;
  comment: string | null;
  user_id: string | null;
  created_at: string | null;
};

const ICONS_MAP: { [key: string]: string } = {
  "KYC Government ID Verification": "badge",
  "KYB TIN Format Check": "pin",
  "KYB TIN Check": "pin",
  "KYB High Risk Country Screening": "public_off",
  "KYB High Risk Industry Screening": "domain_disabled",
  "Proof of Address Verification": "markunread_mailbox",
  "Business Registration Verification": "description",
  "Business Ownership Verification": "description",
  "KYB Sanctions List Screening": "visibility",
  "Adverse Media Event Check": "receipt_long",
  "KYB Adverse Media Screening": "receipt_long",
  "KYC Adverse Media Screening": "receipt_long",
  "KYC PEP Screening": "tour",
  "KYC Sanctions Watchlist Screening": "visibility",
  "KYB Open Source Business Due Diligence Check": "business",
  "KYB Web Research": "screen_search_desktop",
  "KYB MCC Check": "factory",
};

// const periods = [
//   { value: 30, label: "Last 30 days" },
//   { value: 45, label: "Last 45 days" },
//   { value: 90, label: "Last 90 days" },
// ];

const ChecksOverview = () => {
  const parchaApi = useParchaApi();
  const [searchParams] = useSearchParams();
  const agentKey = searchParams.get("agent_key");
  const commandAgentId = searchParams.get("command_agent_id");
  const commandId = searchParams.get("command_id");
  const commandName = searchParams.get("command_name") || "";

  const [agent, setAgent] = useState<Agent | null>(null);
  const [checkFeedbackData, setCheckFeedbackData] = useState<CheckFeedbackDataItem[] | null>(null);
  const [finalPlanChecksOverviewData, setFinalPlanChecksOverviewData] = useState<any[] | null>(null);
  const [endpoints, setEndpoints] = useState<Endpoint[] | null>(null);
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);
  const [checksOverview, setChecksOverview] = useState<CheckDataItem[] | null>(null);
  const [stage, setStage] = useState<"loading" | "idle">("loading");
  // const [selectedPeriod, setSelectedPeriod] = useState(periods[0]);

  const navigate = useNavigate();

  /** FETCHERS START */
  const fetchEndpoints = async () => {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints } = customerAgents as EndpointsAndTenant;
    setEndpoints(endpoints);

    if (agentKey && endpoints) {
      const endpoint = endpoints.find((e) => e.agentKey === agentKey) || null;
      if (endpoint) setEndpoint(endpoint);
    } else if (endpoints.length) {
      navigate(`/checks-overview?agent_key=${endpoints[0].agentKey}`);
      setEndpoint(endpoints[0]);
    }
  };

  const fetchCheckFeedbackData = () => {
    if (agentKey && commandId && commandAgentId && endpoint) {
      setStage("loading");
      parchaApi.getCheckFeedbackData(endpoint.endpointUrl, commandAgentId, commandId).then((data) => {
        setCheckFeedbackData(
          data.sort((a: CheckFeedbackDataItem, b: CheckFeedbackDataItem) =>
            b.created_at && a.created_at ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime() : 0,
          ),
        );
        setStage("idle");
      });
    }
  };

  const fetchChecksDataForAgent = async () => {
    if (endpoint && agentKey) {
      setStage("loading");
      const [checksOverviewData, finalPlanCheckOverviewData] = await Promise.all([
        parchaApi.getChecksOverviewForAgent(endpoint?.endpointUrl, agentKey),
        parchaApi.getFinalPlanChecksOverviewForAgent(endpoint?.endpointUrl, agentKey),
      ]);
      setChecksOverview(checksOverviewData);
      setFinalPlanChecksOverviewData(finalPlanCheckOverviewData);
      setStage("idle");
    }
  };

  const fetchAgent = async () => {
    if (endpoint && agentKey) {
      const agent = await parchaApi.getSimpleAgent(endpoint?.endpointUrl, agentKey);
      setAgent(agent);
    }
  };
  /** FETCHERS END */

  if (import.meta.env.VITE_ENABLE_NEW_CASES_OVERVIEW === "true") {
    return <Navigate to="/cases-overview" replace />;
  }

  // Step 1: Fetch all endpoints to populate the dropdown
  useEffect(() => {
    fetchEndpoints();
  }, []);

  // Step 2: When we have the endpoints and/or agentKey, we select the current endpoint
  useEffect(() => {
    fetchAgent();

    if (endpoints && agentKey) {
      const endpoint = endpoints?.find((e) => e.agentKey === agentKey) || null;
      setEndpoint(endpoint);
    } else if (endpoints?.length) {
      setEndpoint(endpoints[0]);
    }
  }, [endpoints, agentKey]);

  // Step 3: When we have the endpoint, we fetch the checks data based on the search params
  useEffect(() => {
    if (!agentKey) return;

    if (!commandAgentId && !commandId) {
      setCheckFeedbackData(null); // clear the check detail feedback data so that all checks overview data is displayed
      fetchChecksDataForAgent();
    } else {
      fetchCheckFeedbackData();
    }
  }, [agent, commandAgentId, commandId, agentKey]);

  const startDate = format(new Date(2024, 1, 1), "MMMM d, yyyy");

  const allAgentsMap: { [key: string]: any } = useMemo(() => {
    if (!agent) return {};

    const agentGroups = agent?.steps?.filter((step: Step) => step.command_type === "agent_group");
    const agentGroupsByCommandIdMap = agentGroups?.reduce((acc, step) => {
      return { ...acc, [step.command_id]: { ...step } };
    }, {});

    return {
      [agent.agent_key]: {
        agent_config: {
          agent_key: agent.agent_key,
          agent_name: agent.agent_name,
          agent_description: agent.agent_description,
          agent_type: agent.agent_type,
        },
      },
      ...agentGroupsByCommandIdMap,
    };
  }, [agent]);

  const businessChecks = checksOverview?.filter(
    (check) => allAgentsMap[check.agent_key]?.agent_config.agent_type === "kyb",
  );

  const individualChecks = checksOverview?.filter(
    (check) => allAgentsMap[check.agent_key]?.agent_config.agent_type === "kyc",
  );

  const associatedEntitiesChecks = checksOverview?.filter(
    (check) => allAgentsMap[check.agent_key]?.agent_config.agent_type === "entity",
  );

  const endpointOptions = useMemo(() => {
    return endpoints?.map((endpoint: Endpoint) => ({ label: endpoint.agentName, value: endpoint.agentKey })) || [];
  }, [endpoints]);

  const handleEndpointChange = (selectedOption: SingleValue<EndpointSelection>) => {
    if (!selectedOption) return;

    if (endpoint?.agentKey === selectedOption.value) return;

    setChecksOverview(null);
    setFinalPlanChecksOverviewData(null);
    setStage("loading"); // Show loading screen immediately after endpoint selection
    return navigate(`/checks-overview?agent_key=${selectedOption.value}`);
  };

  return (
    <div className="flex flex-col h-screen overflow-y-auto p-5">
      {stage === "loading" && <ParchaLoadingScreen message="Loading Checks Overview data..." />}
      {stage === "idle" && (
        <>
          <header className="flex items-center h-[3.75rem] px-4 py-2 gap-x-5">
            <Select
              className="min-w-[15rem] rounded-md text-xs text-slate-800"
              options={endpointOptions}
              onChange={handleEndpointChange}
              value={endpointOptions.find((option) => option.value === endpoint?.agentKey)}
              isSearchable={false}
            />
            {/* <Select options={periods} value={selectedPeriod} onChange={handleDateChange} isSearchable={false} /> */}
          </header>
          <div className="flex flex-col gap-y-10">
            <div className="flex gap-x-2 items-center text-lg">
              <FactCheck className="text-slate-500" />
              {checkFeedbackData && endpoint ? (
                <Link
                  to={`/checks-overview?agent_key=${endpoint.agentKey}`}
                  className="font-semibold text-brand-purple cursor-pointer"
                >
                  Checks from {startDate} to {format(new Date(), "MMMM d, yyyy")}
                </Link>
              ) : (
                <p className="font-semibold">
                  Checks from {startDate} to {format(new Date(), "MMMM d, yyyy")}
                </p>
              )}
              {checkFeedbackData ? (
                <>
                  <ChevronRight sx={{ fontSize: "1.5rem" }} />
                  <div className="flex gap-x-1 items-center">
                    <span className="font-semibold">{`${commandName} with Feedback`}</span>
                    <span className="text-sm font-normal">({checkFeedbackData.length})</span>
                  </div>
                </>
              ) : null}
            </div>
            {checksOverview?.length || checkFeedbackData?.length ? (
              <Dashboard
                agentKey={endpoint?.agentKey}
                checkFeedbackData={checkFeedbackData}
                businessChecks={businessChecks || []}
                individualChecks={individualChecks || []}
                associatedEntitiesChecks={associatedEntitiesChecks || []}
                finalPlanChecksOverviewData={finalPlanChecksOverviewData || []}
              />
            ) : (
              <ChecksOverviewEmptyState />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const ChecksOverviewEmptyState = () => {
  return (
    <div className="flex flex-col gap-y-5 mx-auto items-center w-[32rem] text-center py-10 mt-[8rem] rounded-lg bg-slate-50 text-slate-500">
      <Icon sx={{ fontSize: "2rem" }} className="material-icons-round text-slate-500">
        fact_check
      </Icon>
      <div>
        <p className="text-slate-500 text-md font-bold">There are currently no checks that have ran.</p>
        <p className="text-slate-500 text-md font-normal">Once you have run checks you will see all of them here.</p>
      </div>
    </div>
  );
};

const CheckOverviewDataEmptyState = () => {
  return (
    <div className="flex flex-col gap-y-5 mx-auto items-center w-[36rem] text-center py-10 mt-[8rem] rounded-lg bg-slate-50 text-slate-500">
      <Icon sx={{ fontSize: "2rem" }} className="material-icons-round text-slate-500">
        thumbs_up_down
      </Icon>
      <div>
        <p className="text-slate-500 text-md font-bold">There is currently no feedback for this check type.</p>
        <p className="text-slate-500 text-md font-normal">
          Once you have have provided feedback on this check, it will show here.
        </p>
      </div>
    </div>
  );
};

const Dashboard = ({
  agentKey,
  businessChecks,
  checkFeedbackData,
  individualChecks,
  associatedEntitiesChecks,
  finalPlanChecksOverviewData,
}: {
  agentKey: string | undefined;
  businessChecks: any[];
  checkFeedbackData: any[] | null;
  individualChecks: any[];
  associatedEntitiesChecks: any[];
  finalPlanChecksOverviewData: any[];
}) => {
  return (
    <div className="flex flex-col gap-y-5">
      <main className="flex flex-col gap-10 flex-1">
        {!checkFeedbackData ? (
          <>
            {finalPlanChecksOverviewData?.length > 0 && (
              <ChecksTable
                data={finalPlanChecksOverviewData}
                columns={[
                  {
                    Header: "Final Check",
                    accessor: "command_name",
                    Cell: ({ row }) => {
                      return (
                        <Link
                          to={`/checks-overview?agent_key=${agentKey}&command_agent_id=${agentKey}&command_id=${row.original.command_id}&command_name=${row.original.command_name}`}
                          className="flex items-center gap-x-3 text-brand-purple cursor-pointer"
                        >
                          <Icon sx={{ fontSize: "1rem" }} className="material-icons-round">
                            fact_check
                          </Icon>
                          <p>Final Recommendation</p>
                        </Link>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        Accuracy Score
                        <Tooltip
                          placement="top"
                          title="The ‘Accuracy Score’ is the # of checks not marked ‘Incorrect’ divided by the # of ‘Completed’ checks."
                        >
                          <InfoIcon sx={{ fontSize: "1rem" }} className="material-icons-round text-brand-purple" />
                        </Tooltip>
                      </div>
                    ),
                    accessor: "accuracy_percentage",
                    Cell: ({ value }) => {
                      return (
                        <div>
                          <span className="font-semibold">{(Number(value) * 100).toFixed(1)}%</span>
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Completed",
                    accessor: "total",
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-green-600">
                          check_circle
                        </Icon>
                        Approve
                      </div>
                    ),
                    accessor: "total_approve",
                    Cell: ({ value }) => {
                      return <span className="text-green-600">{value}</span>;
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-red-600">
                          cancel
                        </Icon>
                        Deny
                      </div>
                    ),
                    accessor: "total_deny",
                    Cell: ({ value }) => {
                      return <span className="text-red-600">{value}</span>;
                    },
                  },

                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-yellow-700">
                          visibility
                        </Icon>
                        Review
                      </div>
                    ),
                    accessor: "total_review",
                    Cell: ({ value }) => {
                      return <span className="text-yellow-700">{value}</span>;
                    },
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          thumb_down
                        </Icon>
                        Incorrect
                      </div>
                    ),
                    accessor: "total_thumbs_down",
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          chat
                        </Icon>
                        Comments
                      </div>
                    ),
                    accessor: "total_comments",
                  },
                ]}
              />
            )}

            {businessChecks?.length > 0 && (
              <ChecksTable
                data={businessChecks}
                columns={[
                  {
                    Header: "Business Check Name",
                    accessor: "command_name",
                    Cell: ({ value, row }) => {
                      return (
                        <Link
                          to={`/checks-overview?agent_key=${agentKey}&command_agent_id=${row.original.agent_key}&command_id=${row.original.command_id}&command_name=${row.original.command_name}`}
                          className="flex items-center gap-x-3 text-brand-purple cursor-pointer"
                        >
                          <Icon sx={{ fontSize: "1rem" }} className="material-icons-round">
                            {ICONS_MAP[value]}
                          </Icon>
                          <p>{value}</p>
                        </Link>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        Accuracy Score
                        <Tooltip
                          placement="top"
                          title="The ‘Accuracy Score’ is the # of checks not marked ‘Incorrect’ divided by the # of ‘Completed’ checks."
                        >
                          <InfoIcon sx={{ fontSize: "1rem" }} className="material-icons-round text-brand-purple" />
                        </Tooltip>
                      </div>
                    ),
                    accessor: "accuracy_percentage",
                    Cell: ({ value }) => {
                      return <span className="font-semibold">{(Number(value) * 100).toFixed(1)}%</span>;
                    },
                  },
                  {
                    Header: "Completed",
                    accessor: "total",
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-green-600">
                          check_circle
                        </Icon>
                        Passed
                      </div>
                    ),
                    accessor: "total_passed_true",
                    Cell: ({ value }) => {
                      return <span className="text-green-600">{value}</span>;
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-red-600">
                          cancel
                        </Icon>
                        Failed
                      </div>
                    ),
                    accessor: "total_passed_false",
                    Cell: ({ value }) => {
                      return <span className="text-red-600">{value}</span>;
                    },
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          thumb_down
                        </Icon>
                        Incorrect
                      </div>
                    ),
                    accessor: "total_thumbs_down",
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          chat
                        </Icon>
                        Comments
                      </div>
                    ),
                    accessor: "total_comments",
                  },
                ]}
              />
            )}

            {individualChecks?.length > 0 && (
              <ChecksTable
                data={individualChecks}
                columns={[
                  {
                    Header: "Individual Check Name",
                    accessor: "command_name",
                    Cell: ({ value, row }) => {
                      return (
                        <Link
                          to={`/checks-overview?agent_key=${agentKey}&command_agent_id=${row.original.agent_key}&command_id=${row.original.command_id}&command_name=${row.original.command_name}`}
                          className="flex items-center gap-x-3 text-brand-purple cursor-pointer"
                        >
                          <Icon sx={{ fontSize: "1rem" }} className="material-icons-round">
                            {ICONS_MAP[value]}
                          </Icon>
                          <p>{value}</p>
                        </Link>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        Accuracy Score
                        <Tooltip
                          placement="top"
                          title="The ‘Accuracy Score’ is the # of checks not marked ‘Incorrect’ divided by the # of ‘Completed’ checks."
                        >
                          <InfoIcon sx={{ fontSize: "1rem" }} className="material-icons-round text-brand-purple" />
                        </Tooltip>
                      </div>
                    ),
                    accessor: "accuracy_percentage",
                    Cell: ({ value }) => {
                      return <span className="font-semibold">{(Number(value) * 100).toFixed(1)}%</span>;
                    },
                  },
                  {
                    Header: "Completed",
                    accessor: "total",
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-green-600">
                          check_circle
                        </Icon>
                        Passed
                      </div>
                    ),
                    accessor: "total_passed_true",
                    Cell: ({ value }) => {
                      return <span className="text-green-600">{value}</span>;
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-red-600">
                          cancel
                        </Icon>
                        Failed
                      </div>
                    ),
                    accessor: "total_passed_false",
                    Cell: ({ value }) => {
                      return <span className="text-red-600">{value}</span>;
                    },
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          thumb_down
                        </Icon>
                        Incorrect
                      </div>
                    ),
                    accessor: "total_thumbs_down",
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          chat
                        </Icon>
                        Comments
                      </div>
                    ),
                    accessor: "total_comments",
                  },
                ]}
              />
            )}

            {associatedEntitiesChecks?.length > 0 && (
              <ChecksTable
                data={associatedEntitiesChecks}
                columns={[
                  {
                    Header: "Associated Entities Check Name",
                    accessor: "command_name",
                    Cell: ({ value, row }) => {
                      return (
                        <Link
                          to={`/checks-overview?agent_key=${agentKey}&command_agent_id=${row.original.agent_key}&command_id=${row.original.command_id}&command_name=${row.original.command_name}`}
                          className="flex items-center gap-x-3 text-brand-purple cursor-pointer cursor-pointer"
                        >
                          <Icon sx={{ fontSize: "1rem" }} className="material-icons-round">
                            {ICONS_MAP[value]}
                          </Icon>
                          <p>{value}</p>
                        </Link>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        Accuracy Score
                        <Tooltip
                          placement="top"
                          title="The ‘Accuracy Score’ is the # of checks not marked ‘Incorrect’ divided by the # of ‘Completed’ checks."
                        >
                          <InfoIcon sx={{ fontSize: "1rem" }} className="material-icons-round text-brand-purple" />
                        </Tooltip>
                      </div>
                    ),
                    accessor: "accuracy_percentage",
                    Cell: ({ value }) => {
                      return <span className="font-semibold">{(Number(value) * 100).toFixed(1)}%</span>;
                    },
                  },
                  {
                    Header: "Completed",
                    accessor: "total",
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-green-600">
                          check_circle
                        </Icon>
                        Passed
                      </div>
                    ),
                    accessor: "total_passed_true",
                    Cell: ({ value }) => {
                      return <span className="text-green-600">{value}</span>;
                    },
                  },
                  {
                    Header: () => (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-red-600">
                          cancel
                        </Icon>
                        Failed
                      </div>
                    ),
                    accessor: "total_passed_false",
                    Cell: ({ value }) => {
                      return <span className="text-red-600">{value}</span>;
                    },
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          thumb_down
                        </Icon>
                        Incorrect
                      </div>
                    ),
                    accessor: "total_thumbs_down",
                  },
                  {
                    Header: (
                      <div className="flex gap-x-1 items-center justify-end">
                        <Icon sx={{ fontSize: "1rem" }} className="material-icons-round text-slate-500">
                          chat
                        </Icon>
                        Comments
                      </div>
                    ),
                    accessor: "total_comments",
                  },
                ]}
              />
            )}
          </>
        ) : checkFeedbackData.length > 0 ? (
          <CheckFeedbackDataTable
            columns={[
              {
                Header: "Case ID",
                accessor: "job_id",
                Cell: ({ value }) => {
                  return (
                    <a
                      href={`/jobs/${agentKey}/${value?.replaceAll("-", "")}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-brand-purple cursor-pointer"
                    >
                      {value ? value.replaceAll("-", "") : "--"}
                    </a>
                  );
                },
              },
              {
                Header: "Name",
                accessor: "input_payload",
                Cell: ({ value }) => {
                  return (
                    <span className="inline-block max-w-[20rem] text-ellipsis overflow-hidden nowrap">
                      {getJobName(value.self_attested_data)}
                    </span>
                  );
                },
              },
              {
                Header: "Recommendation",
                accessor: "recommendation",
                Cell: ({ value }) => {
                  return <span>{value === null ? "Pass" : value}</span>;
                },
              },
              {
                Header: "Feedback",
                accessor: "score",
                Cell: ({ value }) => {
                  return <span>{value === null || value === true ? "Correct" : "Incorrect"}</span>;
                },
              },
              {
                Header: "Comment",
                accessor: "comment",
                Cell: ({ value }) => {
                  return value && value.length > 25 ? (
                    <Tooltip title={value} placement="top">
                      <span className="inline-block max-w-[25ch] text-ellipsis overflow-hidden nowrap">
                        {value || "--"}
                      </span>
                    </Tooltip>
                  ) : (
                    <span className="inline-block max-w-[25ch] text-ellipsis overflow-hidden nowrap">
                      {value || "--"}
                    </span>
                  );
                },
              },
              {
                Header: "Feedback Provider",
                accessor: "user_id",
                Cell: ({ value }) => {
                  return <span>{value || "--"}</span>;
                },
              },
              {
                Header: () => <p className="text-right">Feedback Timestamp</p>,
                accessor: "created_at",
                Cell: ({ value }) => {
                  return (
                    <p className="text-right">{value ? format(new Date(value), "MMM. d, yyyy - h:mm a") : "--"}</p>
                  );
                },
              },
            ]}
            data={checkFeedbackData}
          />
        ) : (
          <CheckOverviewDataEmptyState />
        )}
      </main>
    </div>
  );
};

const ChecksTable = ({ columns, data }: { columns: Column<CheckDataItem>[]; data: CheckDataItem[] }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<CheckDataItem>({
    columns,
    data,
  });

  return (
    <div className="min-w-full bg-white shadow rounded-lg">
      <table {...getTableProps()} className="min-w-full">
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-slate-200">
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  className={twJoin(
                    "py-4 text-xs text-slate-700 tracking-wider font-semibold max",
                    index === 0
                      ? "px-14 text-left w-[24rem]"
                      : index === 1
                        ? "w-[19.8125rem] px-4 text-right"
                        : "px-4 text-right",
                  )}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    className={twJoin(
                      "px-6 py-4 whitespace-nowrap text-xs text-slate-900",
                      index === 0 ? "bg-slate-50 font-semibold" : "text-right",
                    )}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CheckFeedbackDataTable = ({
  columns,
  data,
}: {
  columns: Column<CheckFeedbackDataItem>[];
  data: CheckFeedbackDataItem[];
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<CheckFeedbackDataItem>({
    columns,
    data,
  });

  return (
    <div className="min-w-full bg-white shadow rounded-lg">
      <table {...getTableProps()} className="min-w-full">
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-slate-200">
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  className={twJoin(
                    "p-4 text-xs text-slate-700 tracking-wider font-semibold px-4 text-left",
                    index === 0 || index === 1 ? "w-[20rem]" : "",
                  )}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="p-4 whitespace-nowrap text-xs text-slate-900 text-left">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChecksOverview;
