import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Skeleton,
  Button,
  Chip,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

import { Endpoint, UserDetails } from "../../types";
import InviteUserModal from "./InviteUserModal";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import AdminNavbar from "./AdminNavbar";
import { useUserContext } from "@/contexts/UserContext";

const UsersTable: React.FC = () => {
  const { endpoints, agent: contextAgent, hasRole, rbacService } = useUserContext();
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>(contextAgent?.agent_key || "");
  const [isLoading, setIsLoading] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);
  const [openRoleConfirmation, setOpenRoleConfirmation] = useState(false);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false);
  const [pendingRoleChange, setPendingRoleChange] = useState<{ user: UserDetails; newRole: string } | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isChangingRole, setIsChangingRole] = useState(false);
  const parchaApi = useParchaApi();
  const [adminAgents, setAdminAgents] = useState<Endpoint[]>([]);

  const fetchUsers = useCallback(
    async (agentKey: string) => {
      setIsLoading(true);
      try {
        const fetchedUsers = await parchaApi.getAgentUsers(agentKey);
        setUsers(fetchedUsers || []);
      } catch (error) {
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    },
    [parchaApi],
  );

  const handleInvite = async (newUser: { email: string; role: string }) => {
    try {
      await parchaApi.addAccessToAgent({
        target_user_id: newUser.email,
        agent_key: selectedAgent,
        agent_role: newUser.role,
      });
      setOpenInviteModal(false);
      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error inviting user:", error);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    const newSelectedAgent = event.target.value;
    setSelectedAgent(newSelectedAgent);
    if (newSelectedAgent) {
      fetchUsers(newSelectedAgent);
    } else {
      setUsers([]);
    }
  };

  const handleRoleChangeConfirmation = (user: UserDetails, newRole: string) => {
    setPendingRoleChange({ user, newRole });
    setOpenRoleConfirmation(true);
  };

  const handleRoleChange = async () => {
    if (!pendingRoleChange) return;
    const { user, newRole } = pendingRoleChange;

    setIsChangingRole(true);
    const currentRole = getRoleForSelectedAgent(user);

    try {
      if (newRole === "No Access") {
        // Remove role
        await parchaApi.updateUserRole({
          user_id: user.user_id,
          agent_key: selectedAgent,
          role: currentRole,
          action: "delete",
        });
      } else {
        // Add or change role
        await parchaApi.updateUserRole({
          user_id: user.user_id,
          agent_key: selectedAgent,
          role: newRole,
          action: "add",
        });

        // If changing from an existing role, remove the old role
        if (currentRole !== "No Access") {
          await parchaApi.updateUserRole({
            user_id: user.user_id,
            agent_key: selectedAgent,
            role: currentRole,
            action: "delete",
          });
        }
      }

      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error updating user role:", error);
    } finally {
      setIsChangingRole(false);
      setOpenRoleConfirmation(false);
      setPendingRoleChange(null);
    }
  };

  const handleOpenRemoveConfirmation = (user: UserDetails) => {
    setSelectedUser(user);
    setOpenRemoveConfirmation(true);
  };

  const handleRemoveAccess = async () => {
    if (!selectedUser || !selectedAgent) return;

    setIsRemoving(true);
    const currentRole = getRoleForSelectedAgent(selectedUser);

    try {
      if (currentRole !== "No Access") {
        await parchaApi.updateUserRole({
          user_id: selectedUser.user_id,
          agent_key: selectedAgent,
          role: currentRole,
          action: "delete",
        });
      }
      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error removing user access:", error);
    } finally {
      setIsRemoving(false);
      setSelectedUser(null);
      setOpenRemoveConfirmation(false);
    }
  };

  const getRoleForSelectedAgent = (user: UserDetails) => {
    const role = user.roles.find((r) => r.startsWith(selectedAgent));
    return role ? role.split("_")[1] : "No Access";
  };

  const getAgentName = useCallback(
    (agentKey: string) => {
      return endpoints?.find((agent) => agent.agentKey === agentKey)?.agentName || agentKey;
    },
    [endpoints],
  );

  const roleDescriptions = {
    admin: "All access including member management",
    developer: "API and configuration access",
    analyst: "Data analysis and reporting access",
    reader: "View and run case access",
  };

  useEffect(() => {
    if (endpoints) {
      const filteredAgents = endpoints.filter((agent) => {
        // Check if the user has admin role for this agent
        const isAdmin = rbacService?.checkDynamicRole(agent.agentKey, "admin");
        return isAdmin && agent.rbac === true;
      });
      setAdminAgents(filteredAgents);
    }
  }, [endpoints, hasRole]);

  useEffect(() => {
    if (contextAgent && contextAgent.agent_key) {
      if (adminAgents.some((agent) => agent.agentKey === contextAgent.agent_key)) {
        setSelectedAgent(contextAgent.agent_key);
        fetchUsers(contextAgent.agent_key);
      } else if (adminAgents.length > 0) {
        setSelectedAgent(adminAgents[0].agentKey);
        fetchUsers(adminAgents[0].agentKey);
      }
    }
  }, [contextAgent, adminAgents, fetchUsers]);

  return (
    <>
      <AdminNavbar
        title="Users"
        leftContent={
          <FormControl>
            <InputLabel id="agent-label">Agent</InputLabel>
            <Select
              input={<OutlinedInput label="Agent" />}
              value={selectedAgent}
              onChange={handleAgentChange}
              displayEmpty
              inputProps={{ "aria-label": "Select agent" }}
              size="small"
              sx={{ minWidth: 120 }}
            >
              <MenuItem value="" disabled>
                Select Agent
              </MenuItem>
              {adminAgents.map((agent) => (
                <MenuItem key={agent.agentKey} value={agent.agentKey}>
                  {agent.agentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        rightContent={
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => setOpenInviteModal(true)}
            disabled={!selectedAgent}
          >
            Add Members
          </Button>
        }
      />

      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead>
            <TableRow className="bg-slate-100" sx={{ height: "62px" }}>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>
                Role
                <Tooltip
                  title={
                    <div>
                      {Object.entries(roleDescriptions).map(([role, description]) => (
                        <p key={role}>
                          <strong>{role.charAt(0).toUpperCase() + role.slice(1)}:</strong> {description}
                        </p>
                      ))}
                    </div>
                  }
                  arrow
                >
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!selectedAgent ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>Please select an agent to view users.</Typography>
                </TableCell>
              </TableRow>
            ) : isLoading ? (
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="rectangular" width={60} height={24} />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="circular" width={24} height={24} />
                  </TableCell>
                </TableRow>
              ))
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Stack spacing={2} alignItems="center">
                    <Typography variant="body1">No users have access to this agent yet.</Typography>
                    <Button variant="contained" startIcon={<PersonAddIcon />} onClick={() => setOpenInviteModal(true)}>
                      Invite New Members
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.user_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {user.descope_user.status === "invited" ? (
                      <Typography variant="body1" sx={{ fontStyle: "italic", color: "text.secondary" }}>
                        -
                      </Typography>
                    ) : (
                      <Typography variant="body1">{user.name}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={getRoleForSelectedAgent(user)}
                      onChange={(e) => handleRoleChangeConfirmation(user, e.target.value as string)}
                      size="small"
                      variant="standard"
                      disableUnderline
                      sx={{
                        "&:before": { borderBottom: "none" },
                        "&:after": { borderBottom: "none" },
                        "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
                      }}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="developer">Developer</MenuItem>
                      <MenuItem value="analyst">Analyst</MenuItem>
                      <MenuItem value="reader">Reader</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="right">
                    {user.descope_user.status === "enabled" ? (
                      <Chip
                        label="Active"
                        size="small"
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                        }}
                      />
                    ) : user.descope_user.status === "invited" ? (
                      <Chip
                        label="Invited"
                        size="small"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[300],
                          color: (theme) => theme.palette.grey[700],
                        }}
                      />
                    ) : (
                      user.descope_user.status || "Unknown"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenRemoveConfirmation(user)}>
                      <DeleteIcon className="text-red-600" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <InviteUserModal
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        onInvite={(users) => Promise.all(users.map(handleInvite)).then(() => {})}
        agentKey={selectedAgent}
      />

      <Dialog
        open={openRoleConfirmation}
        onClose={() => !isChangingRole && setOpenRoleConfirmation(false)}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <EditIcon sx={{ marginRight: "8px" }} />
          Change role
          <IconButton
            aria-label="close"
            onClick={() => !isChangingRole && setOpenRoleConfirmation(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={isChangingRole}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mb: 2, mt: 4 }}>
          <DialogContentText>
            Are you sure you want to change the role of {pendingRoleChange?.user.name} to {pendingRoleChange?.newRole}{" "}
            for {getAgentName(selectedAgent)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: 1,
            borderColor: (theme) => theme.palette.divider,
            p: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setOpenRoleConfirmation(false)} disabled={isChangingRole}>
            Cancel
          </Button>
          <Button
            onClick={handleRoleChange}
            variant="contained"
            disabled={isChangingRole}
            startIcon={isChangingRole ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isChangingRole ? "Changing..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveConfirmation}
        onClose={() => !isRemoving && setOpenRemoveConfirmation(false)}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <PersonRemoveIcon sx={{ marginRight: "8px" }} />
          Remove access
          <IconButton
            aria-label="close"
            onClick={() => !isRemoving && setOpenRemoveConfirmation(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={isRemoving}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mb: 2, mt: 4 }}>
          <DialogContentText>
            {selectedUser?.name || selectedUser?.email} will immediately lose access to {getAgentName(selectedAgent)}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: 1,
            borderColor: (theme) => theme.palette.divider,
            p: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setOpenRemoveConfirmation(false)} disabled={isRemoving}>
            Cancel
          </Button>
          <Button
            onClick={handleRemoveAccess}
            variant="contained"
            disabled={isRemoving}
            startIcon={isRemoving ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isRemoving ? "Removing..." : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTable;
