import clsx from "clsx";
import AddressValue, { AddressValueProps } from "./AddressValue";
import PersonIcon from "@mui/icons-material/PersonRounded";
import DocumentValue from "./DocumentValue";
import InfoValue from "./InfoValue";
import { percentageToString, tryToParseDate } from "@/utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { twJoin } from "tailwind-merge";
import { useParchaApi } from "@/hooks/useParchaApi";
import { useState, useEffect, useContext } from "react";
import { ErrorContext } from "@/contexts/ErrorContext";

type IndividualSelfAttestedDataCardProps = {
  caseId: string;
  endpointUrl: string;
  isParchaUser?: boolean;
  level: "normal" | "success" | "failure";
  payload: { [key: string]: any };
};

export default function IndividualSelfAttestedDataCard({
  caseId,
  endpointUrl,
  isParchaUser = false,
  level,
  payload,
}: IndividualSelfAttestedDataCardProps) {
  const [payloadToUse, setPayloadToUse] = useState(payload);
  const parchaApi = useParchaApi();
  const { setError } = useContext(ErrorContext);

  useEffect(() => {
    setPayloadToUse(payload);
  }, [payload]);

  const {
    address,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    title,
    date_of_birth: dateOfBirth,
    country_of_residence: countryOfResidence,
    country_of_nationality: countryOfNationality,
    proof_of_address_documents: proofOfAddressDocuments,
    business_ownership_percentage: businessOwnershipPercentage,
    is_applicant: isApplicant,
    is_business_owner: isBusinessOwner,
  } = payloadToUse;

  const addressProps: AddressValueProps | undefined = address
    ? {
        street1: address.street_1,
        street2: address.street_2,
        city: address.city,
        state: address.state,
        postalCode: address.postal_code,
        countryCode: address.country_code,
      }
    : undefined;

  const calculateAge = (dateOfBirth: string) => {
    let birthDate;
    try {
      birthDate = new Date(dateOfBirth);
      if (isNaN(birthDate.getTime())) {
        throw new Error("Invalid date format");
      }
    } catch (error) {
      console.error("Error parsing date of birth:", error);
      return "Unable to calculate age"; // or handle the error as needed
    }
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      return age - 1 + " years old";
    }
    return age + " years old";
  };

  if ((!firstName || !firstName.length) && (!lastName || !lastName.length)) return null;
  const titleSuffixes = [];
  if (isApplicant) titleSuffixes.push("Applicant");
  if (isBusinessOwner) titleSuffixes.push("Business Owner");

  const fullTitle = title ? [title, ...titleSuffixes].join(" ") : titleSuffixes.join(" ");

  const handleGenerateURLsClick = async () => {
    if (isParchaUser) {
      const updatedPayload = await parchaApi.getWorkingURLs(endpointUrl, {
        case_id: caseId,
        self_attested_data: payload,
      });
      setPayloadToUse(updatedPayload);
    }
  };

  const name = `${firstName}${middleName ? ` ${middleName}` : ""} ${lastName}`;
  return (
    <div
      className={clsx("w-full h-auto flex flex-col gap-2 border rounded-lg text-sm", {
        "border-green-300": level === "success",
        "border-red-300": level === "failure",
        "border-slate-300": level === "normal",
      })}
    >
      <div
        className={twJoin(
          "flex justify-between rounded-t-md p-4 border-b",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-300"
            : level === "failure"
              ? "bg-red-100 border-b-red-300"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="flex flex-col justify-center">
          <div>
            <div className="flex gap-2 items-center text-sm">
              <PersonIcon className="text-brand-purple" sx={{ fontSize: "1rem" }} />
              <div className="font-semibold">{name}</div>
            </div>
            {fullTitle && <span className="text-xs">{fullTitle}</span>}
          </div>
        </div>
        {isParchaUser && (
          <button onClick={handleGenerateURLsClick}>
            <AdminPanelSettingsIcon className="text-brand-purple" sx={{ fontSize: "1.5rem" }} />
          </button>
        )}
      </div>
      <div className="flex flex-col gap-2 justify-center py-3 px-4 text-xs">
        {typeof firstName === "string" && <InfoValue label="First Name" value={firstName} />}
        {typeof middleName === "string" && <InfoValue label="Middle Name" value={middleName} />}
        {typeof lastName === "string" && <InfoValue label="Last Name" value={lastName} />}
        {typeof dateOfBirth === "string" ? (
          <InfoValue label="Date of Birth" value={tryToParseDate(dateOfBirth.replaceAll("-", "/"))} />
        ) : null}
        {typeof dateOfBirth === "string" ? <InfoValue label="Age" value={calculateAge(dateOfBirth)} /> : null}
        {countryOfNationality ? <InfoValue label="Country of Nationality" value={countryOfNationality} /> : null}
        {countryOfResidence ? <InfoValue label="Country of Residence" value={countryOfResidence} /> : null}
        {addressProps &&
          !Object.entries(addressProps).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
            <AddressValue {...addressProps} />
          )}
        {typeof businessOwnershipPercentage === "number" && (
          <InfoValue label="Percent Ownership" value={`${percentageToString(businessOwnershipPercentage)}`} />
        )}

        {proofOfAddressDocuments?.length ? (
          <>
            <span className="font-semibold">Proof of Address Documents: </span>
            <div>
              {proofOfAddressDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                <DocumentValue
                  key={`${fileName}${url}`}
                  fileName={fileName}
                  url={`${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                  maxWidth={250}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
