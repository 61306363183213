import { Check, FlagOutlined, FlagRounded } from "@mui/icons-material";
import { twMerge } from "tailwind-merge";
import { Feedback } from "@/types";

const baseButtonStyles = "w-6 h-6 p-0.5 rounded border";
const loadingButtonStyles = "border-slate-200 bg-slate-100 animate-pulse flex items-center justify-center";
const iconStyles = { fontSize: "1rem" };

type FeedbackButtonProps = {
  onClick: (e: React.MouseEvent) => void;
  className: string;
  icon: React.ReactNode;
};

const FeedbackButton = ({ onClick, className, icon }: FeedbackButtonProps) => (
  <button
    className={twMerge(baseButtonStyles, className)}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    {icon}
  </button>
);

type FeedbackButtonsProps = {
  className?: string;
  isLoadingFeedback: boolean;
  isFail: boolean;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  feedback?: Feedback;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  rowId: string;
  setFeedbackComment: (value: string) => void;
  setShowFeedbackComment: (value: boolean) => void;
};

const FeedbackButtons = ({
  className,
  isLoadingFeedback,
  isFail,
  flaggedSelected,
  setFlaggedSelected,
  feedback,
  handleSubmitFeedback,
  rowId,
  setFeedbackComment,
  setShowFeedbackComment,
}: FeedbackButtonsProps) => {
  const getFlagButtonStyles = () => {
    if (flaggedSelected || feedback?.score === false) {
      return "bg-red-600 border-red-500 text-white";
    }
    return isFail
      ? "hover:bg-red-200 border-red-200 text-red-700"
      : "hover:bg-slate-200 border-slate-200 text-slate-500";
  };

  const getCheckButtonStyles = () => {
    if (feedback?.score) {
      return "bg-green-600 border-green-600 text-white";
    }
    if (flaggedSelected && feedback?.score) {
      return "bg-opacity-50 text-white border-green-200";
    }
    return isFail
      ? "hover:bg-red-200 border-red-200 text-red-700"
      : "border-slate-200 hover:bg-slate-200 text-slate-500";
  };

  const handleCheckClick = () => {
    setFlaggedSelected(false);
    setFeedbackComment("");
    setShowFeedbackComment(false);
    handleSubmitFeedback(true, rowId, "");
  };

  if (isLoadingFeedback) {
    return (
      <div className="ignore-in-pdf print:hidden flex items-center gap-x-1">
        <div className={twMerge(baseButtonStyles, loadingButtonStyles)}>
          <FlagOutlined sx={iconStyles} className="text-slate-300" />
        </div>
        <div className={twMerge(baseButtonStyles, loadingButtonStyles)}>
          <Check sx={iconStyles} className="text-slate-300" />
        </div>
      </div>
    );
  }

  return (
    <div className="ignore-in-pdf print:hidden flex items-center gap-x-1">
      <FeedbackButton
        onClick={() => setFlaggedSelected(!flaggedSelected)}
        className={getFlagButtonStyles()}
        icon={
          flaggedSelected || feedback?.score === false ? (
            <FlagRounded sx={iconStyles} />
          ) : (
            <FlagOutlined sx={iconStyles} />
          )
        }
      />
      <FeedbackButton onClick={handleCheckClick} className={getCheckButtonStyles()} icon={<Check sx={iconStyles} />} />
    </div>
  );
};

export default FeedbackButtons;
